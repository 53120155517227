export const AppUrls = {
    LogIn: 'login',
    Dashboard: 'dashboard',
    Clients: 'clients',
    Faq: 'faq',
    News: 'news',
    Form: 'form',
    Help: 'help',
    User: 'user',
    Demogroup: 'demogroup',
    PaymentBilling: 'payment_billing',
    Postpaid: 'postpaid',
};
