import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import {
  HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse,
} from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from './services/authentication.service';
import { catchError } from 'rxjs/operators';
import { Alert, alert } from '../shared/models/alert';

@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {

  constructor(
    public auth: AuthenticationService,
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (this.auth.getToken()) {
      if (this.auth.getnowdata()) {
        req = req.clone({ setHeaders: { Authorization: this.auth.getToken(), module: this.auth.getnowdata() } });
      }
    }
    req = req.clone({ url: req.url });
    // req = req.clone({ url: environment.API_HOST + req.url });
    req = req.clone({ body: cleanObject(req.body) });

    return next.handle(req).pipe(catchError(err => {
      if (err instanceof HttpErrorResponse && err.status === 401) {
        this.handleauthorization();
        return throwError(err);
      } else {
        return throwError(err);
      }
    }));
  }

  private handleauthorization() {
    Alert.ConfirmSession({
      title: 'เซสชั่นของคุณหมดอายุกรุณาเข้าสู่ระบบอีกครั้ง',
    }).then((swal) => {
      if (!swal.value) { return; }
      this.auth.logout();
      location.reload();
      alert.isalert = false;
    });
  }
}

export function cleanObject<T>(object: T): T {
  const json = JSON.stringify(object, (_, value) => {
    if (typeof value === 'string') {
      return value.trim();
    }

    return value;
  });

  return JSON.parse(json);
}
