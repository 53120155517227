<footer class="text-secondary">
    <ul class="list-group">
        <li class="list-item">
            <a class="text-secondary">
                <small>© 2021 BCP.</small>
            </a>
        </li>
        <li class="list-item">
            Terms
        </li>
        <li class="list-item">
            Privacy
        </li>
        <li class="list-item">
            Security
        </li>
        <li class="list-item">
            Status
        </li>
        <li class="list-item">
            <a [routerLink]="['', 'help']">Help</a>
        </li>
    </ul>

    <ul class="list-group">
        <li class="list-item">
            v{{ version }}
        </li>
    </ul>

    <ul class="list-group">
        <li class="list-item">
            Contact
        </li>
        <li class="list-item">
            Pricing
        </li>
        <li class="list-item">
            API
        </li>
        <li class="list-item">
            Training
        </li>
        <li class="list-item">
            Blog
        </li>
        <li class="list-item">
            About
        </li>
    </ul>
</footer>
