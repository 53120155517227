import { Routes } from '@angular/router';
import { AppUrls } from 'src/app/shared/models/app.url';

export const publicRoutes: Routes = [
    { path: '', redirectTo: AppUrls.LogIn, pathMatch: 'full', },
    {
        path: AppUrls.LogIn,
        loadChildren: () => import('../../modules/login/login.module').then(m => m.LoginModule),
        data: { title: 'เข้าสู่ระบบ' }
    },
];
