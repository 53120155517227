import Swal, { SweetAlertOptions, SweetAlertResult } from 'sweetalert2';
// import Swal, { SweetAlertOptions, SweetAlertResult } from 'sweetalert2';

export const Alert = {
  Success: (swalOptions: SweetAlertOptions = {}): Promise<SweetAlertResult> => {
    return ToastSuccess.fire(Object.assign({}, {
    }, swalOptions));
  },
  Error: (swalOptions: SweetAlertOptions = {}): Promise<SweetAlertResult> => {
    if (alert.isalert) {
      return;
    }
    return ToastError.fire(Object.assign({}, {
    }, swalOptions));
  },
  Confirm: (swalOptions: SweetAlertOptions = {}): Promise<SweetAlertResult> => {
    return Swal.fire(Object.assign({}, defaultOptionsAlert, {

    }, swalOptions));
  },
  ConfirmDanger: (swalOptions: SweetAlertOptions = {}): Promise<SweetAlertResult> => {
    return Swal.fire(Object.assign({}, defaultOptionsAlert, {
      confirmButtonColor: '#dc3545',
    }, swalOptions));
  },
  ConfirmSession: (swalOptions: SweetAlertOptions = {}): Promise<SweetAlertResult> => {
    console.log(alert.isalert);
    if (!alert.isalert) {
      alert.isalert = true;
      return Swal.fire(Object.assign({}, defaultOptionsAlertsession, {
        confirmButtonColor: '#dc3545',
      }, swalOptions));
    }
  },
};

export const alert = {
  isalert: false
};

const defaultOptionsAlert: SweetAlertOptions = {
  icon: 'warning',
  showCancelButton: true,
  focusConfirm: false,
  focusCancel: true,
  heightAuto: false,
};

const defaultOptionsAlertsession: SweetAlertOptions = {
  icon: 'warning',
  showCancelButton: false,
  focusConfirm: false,
  focusCancel: true,
  heightAuto: false,
};

const defaultMaxin: SweetAlertOptions = {
  toast: true,
  position: 'bottom',
  showConfirmButton: false,
  timer: 3000,
};

const ToastSuccess = Swal.mixin({
  ...defaultMaxin,
  icon: 'success',
  title: 'บันทึกสำเร็จ',
  text: '',
  background: '#e2ffe8',
});

const ToastError = Swal.mixin({
  ...defaultMaxin,
  icon: 'error',
  title: 'บันทึกไม่สำเร็จ',
  text: '',
  background: '#ffd3d7',
  timer: 5000,
});
