import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpRequest,
  HttpEvent,
  HttpErrorResponse
} from '@angular/common/http';
import { Router } from '@angular/router';
import { LocalStorageService } from './local-storage.service';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { Pagination } from '../utils/type';

/**
* A wrapper around Angular's `HTTPClient`.
* Each request will automatically append URL with current API Host URL and
* add `Authorization` JWT Token to header.
*/
@Injectable()
export class HttpService {
  constructor(
    private router: Router,
    private httpClient: HttpClient,
    private localStorage: LocalStorageService) {
    }

    /**
    * Make a `GET` Request.
    *
    * @example
    * this.http.get('/example', {
      * params: {
        *  name: 'blah'
        * }
        * });
        * @param url Relative url to make a `GET` Request.
        * @param options Additional options for request.
        */
        get(url: string, options: object = {}): Observable<any> {
          return this.httpClient.get<any>(
            `${this.API_HOST}${url}`, this.getRequestOptions(options)
            ).pipe(
              map(res => this.captureAuthToken(res)),
              catchError(this.httpErrorHandler)
              );
            }

            /**
            * Make a `POST` Request.
            *
            * @param url Relative url to make `POST` Request.
            * @param body Request body.
            * @param options Additional options for request.
            */
            post(url: string, body: object, options: object = {}): Observable<any> {
              return this.httpClient.post<any>(
                `${this.API_HOST}${url}`, body, this.getRequestOptions(options)
                ).pipe(
                  map(res => this.captureAuthToken(res)),
                  catchError(this.httpErrorHandler)
                  );
                }

                /**
                * Make a `PUT` Request.
                *
                * @param url Relative url to make `PUT` Request.
                * @param body Request body.
                * @param options Additional options for request.
                */
                put(url: string, body: object, options: object = {}): Observable<any> {
                  return this.httpClient.put<any>(
                    `${this.API_HOST}${url}`, body, this.getRequestOptions(options)
                    ).pipe(
                      map(res => this.captureAuthToken(res)),
                      catchError(this.httpErrorHandler)
                      );
                    }

                    /**
                    * Make a `PATCH` Request.
                    *
                    * @param url Relative url to make `PATCH` Request.
                    * @param body Request body.
                    * @param options Additional options for request.
                    */
                    patch(url: string, body: object, options: object = {}): Observable<any> {
                      return this.httpClient.patch<any>(
                        `${this.API_HOST}${url}`, body, this.getRequestOptions(options)
                        ).pipe(
                          map(res => this.captureAuthToken(res)),
                          catchError(this.httpErrorHandler)
                          );
                        }

                        /**
                        * Make a `DELETE` Request.
                        *
                        * @param url Relative url to make `DELETE` Request.
                        * @param options Additional options for request.
                        */
                        delete(url: string, options: object = {}): Observable<any> {
                          return this.httpClient.delete<any>(
                            `${this.API_HOST}${url}`, this.getRequestOptions(options)
                            ).pipe(
                              map(res => this.captureAuthToken(res)),
                              catchError(this.httpErrorHandler)
                              );
                            }

                            /**
                            * Get request options.
                            *
                            * @param options Additonal options for request.
                            */
                            private getRequestOptions(options: object): object {
                              let headers = new HttpHeaders();
                              const authorizeToken = this.localStorage.get('Authorization');

                              if (authorizeToken) {
                                headers = headers.append('Authorization', String(authorizeToken));
                              }

                              return Object.assign({}, { headers: headers }, options);
                            }

                            /**
                            * Capture authorize token from API and then update localStorage.
                            *
                            * @param res HttpResponse
                            */
                            private captureAuthToken(res: any): any {
                              if (!this.capturableResponseType(res)) {
                                return;
                              }

                              if (res.Token !== undefined) {
                                this.localStorage.set('KFA_TOKEN', res.Token);
                              }

                              if (res.body !== undefined) {
                                return res.body;
                              }

                              return res;
                            }

                            /**
                            * List of respone types that we can capture the auth token.
                            */
                            private capturableResponseType(res: object) {
                              return res.constructor.name.includes('HttpResponse') ||
                              res.constructor.name.includes('Array') ||
                              res.constructor.name.includes('Object') ||
                              res.constructor.name.includes('String');
                            }

                            /**
                            * Get API Url
                            */
                            private get API_HOST(): string {
                              return environment['API_HOST'];
                            }

                            /**
                            * Get error handler.
                            */
                            private get httpErrorHandler(): (error: HttpErrorResponse) => Observable<never> {
                              return (error: HttpErrorResponse) => {
                                if (error.status === 401) {
                                  this.localStorage.remove('User');
                                  this.localStorage.remove('Authorization');
                                  this.router.navigateByUrl('/login');
                                  // handle unauthorized error here.
                                } else if (error.status === 403) {
                                  return throwError({
                                    message: 'สิทธิ์ของท่านไม่สามารถใช้งานส่วนนี้ได้',
                                    status: error.status,
                                    statusText: error.statusText
                                  });
                                }

                                return throwError({
                                  message: error.error.Message ? error.error.Message : error.message,
                                  status: error.status,
                                  statusText: error.statusText
                                });
                              };
                            }

                            /**
                            * Make a paginated `GET` Request.
                            *
                            * @param url Url string.
                            * @param page Page number.
                            * @param limit Item per page.
                            * @param filter Filter string.
                            */
                            pagination(
                              url: string,
                              page: number | string = 1,
                              limit: number | string = 1,
                              filter: string = ''
                              ): Observable<Pagination<any>> {
                                const filterString = `${filter === '' ? '' : `&filter=${filter}`}`;
                                return this.get(`${url}?page=${page}&limit=${limit}${filterString}`);
                              }
                            }
