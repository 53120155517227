import { environment } from './../../../environments/environment';
import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Alert } from 'src/app/shared/models/alert';
import { Subject } from 'rxjs';
import moment from 'moment';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class AppService {
  // private api = `https://dev-api.koiauction1.com/`;
  private api = environment.API_HOST;
  userUpdated = new Subject();
  public dateTimeNow: Date;
  public dateTimeNowAddMin: Date;

  constructor(
    private http: HttpClient,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {}

  get isBrowser(): boolean {
    return isPlatformBrowser(this.platformId);
  }

  getPagination(url, pagination, queryParamsStart = true) {
    const pagi = `page=${pagination.page}&limit=${pagination.limit}&name=${pagination.name}&filter=${pagination.filter}`;
    return this.http.get<any>(`${url}${queryParamsStart ? '?' : '&'}${pagi}`);
  }

  getRole() {
    return this.http.get<any>(`bo/user/role/get`);
  }

  processCheckFile(event) {
    return new Promise<File>((resolve) => {
      const file: File = event.target.files[0];
      if (!file) {
        return resolve(null);
      }
      const filetype = file.type.split('/')[1];
      if (filetype !== 'jpeg' && filetype !== 'png') {
        Alert.Error({ title: 'เลือกไฟล์รูปสกุล jpeg หรือ png' });
        return resolve(null);
      }
      resolve(file);
    });
  }

  processCheckFiles(event) {
    return new Promise<File[]>((resolve) => {
      if (!event.target.files) {
        return resolve(null);
      }
      const files: File[] = Object.values(event.target.files);
      files.filter((file) => {
        const filetype = file.type.split('/')[1];
        if (filetype !== 'jpeg' && filetype !== 'png') {
          Alert.Error({ title: 'เลือกไฟล์รูปสกุล jpeg หรือ png' });
          return false;
        }
        return true;
      });
      resolve(files);
    });
  }

  toBase64(file: File) {
    return new Promise<string>((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
    });
  }

  updateDateTimeNow() {
    return new Promise<any>((resolve) => {
      const xhr = new XMLHttpRequest();
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4 && xhr.status === 200) {
          const dataDate = JSON.parse(xhr.responseText);
          const formatDate = `${dataDate.Current} +07:00`;
          this.dateTimeNow = moment(formatDate, 'MM/DD/YYYY HH:mm:ss aa ZZ').utcOffset(7).local(true).toDate();
          this.dateTimeNowAddMin = moment(formatDate, 'MM/DD/YYYY HH:mm:ss aa ZZ')
            .utcOffset(7)
            .local(true)
            .add(1, 'minutes')
            .toDate();
          resolve();
        }
      };
      xhr.open('GET', `${this.api}api/datetime/now`);
      xhr.send();
    });
  }
}
