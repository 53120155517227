import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MatDrawer } from '@angular/material';
import { AuthenticationService } from 'src/app/core/services/authentication.service';
import { Alert } from 'src/app/shared/models/alert';
import { AppUrls } from 'src/app/shared/models/app.url';
import { AppService } from 'src/app/core/services/app.service';
import { finalize } from 'rxjs/operators';
import { menuSidebar } from 'src/app/shared/models/menu-sidebar';


@Component({
  selector: 'kfa-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavBarComponent implements OnInit {
  menuSidebar = menuSidebar;
  @Output() openedChange = new EventEmitter();
  @Input() opened: boolean;
  @Input() drawer: MatDrawer;
  AppUrls = AppUrls;
  user;

  constructor(
    private auth: AuthenticationService,
    private app: AppService,
  ) {
    this.user = this.auth.getUser();
    this.app.userUpdated.subscribe(res => {
      this.user = this.auth.getUser();
    });
  }

  ngOnInit() {
  }

  drawerChanges() {
    this.openedChange.emit(!this.opened);
  }

  logout() {
    Alert.ConfirmDanger({
      title: 'ต้องการออกระบบ?',
    }).then((swal) => {
      if (!swal.value) { return; }
      this.auth.logout();
      location.reload();
     });
  }

}
