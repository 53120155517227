import { ModalEditPasswordsComponent } from './components/modal-edit-passwords/modal-edit-passwords.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxBootstrapModule } from './modules/ngx-bootstrap.module';
import { AngularMaterialModule } from './modules/angular-material.module';
import { PanelContianerComponent } from './components/panel-contianer/panel-contianer.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ModalLoginComponent } from './components/modal-login/modal-login.component';
import { NgxMultiLineEllipsisModule } from 'ngx-multi-line-ellipsis';
import { AutosizeModule } from 'ngx-autosize';
import { ValidationDirective } from './directives/validation.directive';
import { ModalFormUserComponent } from './components/modal-form-user/modal-form-user.component';
import { ModalEditUserComponent } from './components/modal-edit-user/modal-edit-user.component';
import { LightboxModule } from '@ngx-gallery/lightbox';
import { GalleryModule } from '@ngx-gallery/core';
import { ThaiBahtPipeModule } from 'ngx-thaibahtpipe';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { ImgloadingDirective } from './directives/imgloading.directive';
import { ValidateMessageComponent } from './validate-message/validate-message.component';

@NgModule({
  declarations: [
    PanelContianerComponent,
    ModalLoginComponent,
    ValidationDirective,
    ModalFormUserComponent,
    ModalEditUserComponent,
    ModalEditPasswordsComponent,
    ImgloadingDirective,
    ValidateMessageComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgxBootstrapModule,
    NgSelectModule,
    NgxMultiLineEllipsisModule,
    AngularMaterialModule,
    LightboxModule,
    ThaiBahtPipeModule,
    GalleryModule.withConfig({
      loadingMode: 'indeterminate',
    }),
    NgxIntlTelInputModule,
  ],
  exports: [
    ValidationDirective,
    FormsModule,
    ReactiveFormsModule,
    NgxBootstrapModule,
    AngularMaterialModule,
    NgxMultiLineEllipsisModule,
    NgSelectModule,
    ThaiBahtPipeModule,
    AutosizeModule,
    PanelContianerComponent,
    ModalLoginComponent,
    ModalFormUserComponent,
    ModalEditUserComponent,
    LightboxModule,
    GalleryModule,
    NgxIntlTelInputModule,
    ModalEditPasswordsComponent,
    ImgloadingDirective,
    ValidateMessageComponent,
  ],
})
export class SharedModule { }
