import { AuctionModule } from './../../modules/auction/auction.module';
import { Routes } from '@angular/router';
import { AppUrls } from 'src/app/shared/models/app.url';

export const secureRoutes: Routes = [
  {
    path: '',
    redirectTo: '/dashboard',
    pathMatch: 'full',
  },
  {
    path: 'dashboard',
    loadChildren: () => import('../../modules/dashboard/dashboard.module').then((m) => m.DashboardModule),
    data: { title: 'แผงควบคุม' },
  },
  {
    path: 'clients', //AppUrls.Clients
    loadChildren: () => import('../../modules/clients/clients.module').then((m) => m.ClientsModule),
    data: { title: 'สมาชิก' },
  },
  {
    path: 'users',
    loadChildren: () => import('../../modules/clients/user/user.module').then((m) => m.UserModule),
    data: { title: 'ผู้ดูแลระบบ' },
  },
  {
    path: 'edit-user',
    // tslint:disable-next-line:max-line-length
    loadChildren: () =>
      import('../components/navbar/navbar-edit-user-data/navbar-edit-user-data.module').then(
        (m) => m.NavbarEditUserDataModule
      ),
    data: { title: 'แก้ไขข้อมูล' },
  },
  {
    path: 'invoice',
    // tslint:disable-next-line:max-line-length
    loadChildren: () => import('../../modules/invoice/invoice.module').then((m) => m.InvoicModule),
    data: { title: 'ใบแจ้งหนี้' },
  },
  {
    path: 'setting',
    // tslint:disable-next-line:max-line-length
    loadChildren: () => import('../../modules/setting/setting.module').then((m) => m.SettingModule),
    data: { title: 'ตั้งค่า' },
  },
  {
    path: 'settingmodule',
    // tslint:disable-next-line:max-line-length
    loadChildren: () => import('../../modules/editmodules/editmodules.module').then((m) => m.EditmodulesModule),
    data: { title: 'โมดูล' },
  },
  {
    path: 'permission',
    // tslint:disable-next-line:max-line-length
    loadChildren: () => import('../../modules/permission/permission.module').then((m) => m.PermissionModule),
    data: { title: 'การเข้าถึง' },
  },
  {
    path: 'category',
    // tslint:disable-next-line:max-line-length
    loadChildren: () => import('../../modules/category/category.module').then((m) => m.CategoryModule),
    data: { title: 'ชนิดปลา' },
  },
  {
    path: 'news',
    loadChildren: () => import('src/app/modules/news/news.module').then((m) => m.NewsModule),
    data: { title: 'ข่าวสาร' },
  },
  {
    path: 'store',
    loadChildren: () => import('src/app/modules/store/store.module').then((m) => m.StoreModule),
    data: { title: 'ร้านค้า' },
  },
  // {
  //   path: 'dashboard',
  //   loadChildren: () => import('src/app/modules/dashboard/dashboard.module').then(m => m.DashboardModule),
  //   data: { title: 'Dashboard' },
  // },
  // {
  //   path: 'help',
  //   loadChildren: () => import('src/app/modules/help/help.module').then(m => m.HelpModule),
  //   data: { title: 'Help' },
  // },
  {
    path: 'faq',
    loadChildren: () => import('src/app/modules/faq/faq.module').then((m) => m.FaqModule),
    data: { title: 'คำถามที่พบ่อย' },
  },
  {
    path: 'auction',
    loadChildren: () => import('src/app/modules/auction/auction.module').then((m) => m.AuctionModule),
    data: { title: 'การประมูล' },
  },
  {
    path: 'customer-support',
    loadChildren: () =>
      import('src/app/modules/customer-support/customer-support.module').then((m) => m.CustomerSupportModule),
    data: { title: 'ฝ่ายบริการลูกค้า' },
  },

  // new code 
  {
    path : 'attributes',
    loadChildren: () => import('src/app/modules/attributes/attributes-routing.module').then((m) => m.AttributesRoutingModule),
    data: {title: 'คุณลักษณะ'}
  },
  {
    path: AppUrls.Demogroup,
    loadChildren: () => import('../../modules/demo-group/demo-group.module').then((m) => m.DemoGroupModule),
    data: { title: 'บัญชีทดลอง' },
  },
  {
    path: AppUrls.PaymentBilling,
    loadChildren: () => import('../../modules/payment-billing/payment-billing.module').then((m) => m.PaymentBillingModule),
    data: { title: 'การชำระเงิน' },
  },
  {
    path: AppUrls.Postpaid,
    loadChildren: () => import('../../modules/postpaid/postpaid.module').then((m) => m.PostpaidModule),
    data: { title: 'จัดการวันใช้งาน' },
  },


  { path: '**', redirectTo: AppUrls.Clients, pathMatch: 'full' },
];
