import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { LocalStorageService } from '../core/services/local-storage.service';
import { BehaviorSubject } from 'rxjs';
import { AuctionService } from './auction.service';

@Injectable({
  providedIn: 'root',
})
export class CustomerSupportService {
  private connection: SignalR.Hub.Connection;
  private inboxHub: SignalR.Hub.Proxy;
  private auctionHub: SignalR.Hub.Proxy;
  private notificationAdminHub: SignalR.Hub.Proxy;

  inboxes$ = new BehaviorSubject<any[]>([]);
  auction$ = new BehaviorSubject<any[]>([]);
  notiAdmin$ = new BehaviorSubject<any[]>([]);
  replyMessages$ = new BehaviorSubject<any[]>([]);
  auctionStarted$ = new BehaviorSubject<boolean>(false);
  inboxSelected$ = new BehaviorSubject(null);
  chatSubscription = new BehaviorSubject<any[]>([]);

  constructor(
    private localStorageService: LocalStorageService,
    ) {
    this.configure();
    this.setClientContext();
    this.setAuctionHub();
    this.connect();
  }

  getInbox(customerId) {
    return this.inboxHub.invoke('getInbox', customerId);
  }

  selectInbox(inbox) {
    this.inboxSelected$.next(inbox);
  }

  getReplies(inboxId) {
    return this.inboxHub.invoke('getReplies', inboxId).done((replies) => {
      this.replyMessages$.next(replies);
    });
  }

  reply(val) {
    this.inboxHub.invoke('reply', val).done(() => console.log('Replied'));
  }

  leaveInbox(id) {
    this.inboxHub.invoke('leaveInbox', id).done(() => console.log('Leave inbox'));
  }

  read(inboxId) {
    this.inboxHub
      .invoke('read', inboxId)
      .done(() => console.log('Read'))
      .fail((err) => console.error(err));
  }

  private configure() {
    this.connection = $.hubConnection(`${environment.API_HOST + 'signalr'}`, { useDefaultPath: false });
    this.connection.qs = {
      token: this.localStorageService.get('KFA_TOKEN'),
    };
    this.inboxHub = this.connection.createHubProxy('inboxHub');
    this.auctionHub = this.connection.createHubProxy('auctionHub');
    this.notificationAdminHub = this.connection.createHubProxy('notificationAdminHub');
  }

  private connect() {
    this.connection.start().done(() => {
      console.log('Connected!!!');

      this.inboxHub.invoke('init').done(() => {
        console.log('Init!!!');
      });

      this.notificationAdminHub.invoke('init').done(() => {
        console.log('notificationAdminHub Init!!!');
      });
    });
  }

  private setClientContext() {
    this.inboxHub.on('updateInboxes', (inboxes) => {
      this.inboxes$.next(inboxes);
    });

    this.inboxHub.on('UpdateReplies', (replies) => {
      this.replyMessages$.next(replies);
    });

    this.notificationAdminHub.on('UpdateModuleNoti', (noti) => {
      this.notiAdmin$.next(noti);
    });
  }

  connectHub(AucId: number) {
    this.auctionHub.invoke('init', AucId).done(() => {
      this.auctionStarted$.next(true);
      console.log('Init Auc!!!');
    });
  }

  private setAuctionHub() {
    this.auctionHub.on('updateChat', (chat) => {
      this.chatSubscription.next(chat);
    });
  }


}
