import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDrawer } from '@angular/material';
import { Subscription } from 'rxjs';
import { Router, NavigationEnd } from '@angular/router';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';
import { AppUrls } from 'src/app/shared/models/app.url';
import { Alert } from 'src/app/shared/models/alert';

@Component({
  selector: 'kfa-secure',
  templateUrl: './secure.component.html',
  styleUrls: ['./secure.component.scss']
})
export class SecureComponent implements OnInit {
  openSidebar = true;
  @ViewChild('drawer', { static: true }) drawer: MatDrawer;

  Subscriptions: Subscription[] = [];
  constructor(
    private storage: LocalStorageService,
    private router: Router,
  ) {
    const userRole = this.storage.get('KFA_USER').UserRoleCode;
    this.Subscriptions.push(
      this.router.events.subscribe(res => {
        if (res instanceof NavigationEnd) {
          const url = res.url.split('/')[1].split('?')[0];
          if (userRole === 'ADM' && AppUrls.User === url) {
            Alert.Error({ title: 'คุณไม่มีสิทธิ์เข้าหน้านี้'});
            return this.router.navigate(['']);
          }
        }
      })
    );
  }

  ngOnInit() {
  }

  drawerChanges(event: boolean) {
    this.openSidebar = event;
  }

}
