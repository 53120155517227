import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { Router, ActivatedRoute, NavigationEnd, ResolveEnd } from '@angular/router';
import { NgxUiLoaderService, NgxUiLoaderConfig } from 'ngx-ui-loader';
import { map, filter, mergeMap, take } from 'rxjs/operators';
import { LocalStorageService } from './core/services/local-storage.service';
import { HttpService } from './core/services/http.service';
import { AuthenticationService } from './core/services/authentication.service';

@Component({
  selector: 'kfa-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'kfa-backoffice';
  config: NgxUiLoaderConfig;
  UserRole: string;
  modules: any;
  isLoading = true;
  constructor(
    private titleService: Title,
    private router: Router,
    private activatedRouter: ActivatedRoute,
    private ngxUiLoaderService: NgxUiLoaderService,
    private storage: LocalStorageService,
    private http: HttpService,
    private aut: AuthenticationService
  ) {
    this.config = this.ngxUiLoaderService.getDefaultConfig();
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.activatedRouter),
        map((route) => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        filter((route) => route.outlet === 'primary'),
        mergeMap((route) => route.data)
      )
      .subscribe((event) => {
        if (!environment.production) {
          this.titleService.setTitle(`${event.title} - DABO | ${environment.environmentname}`);
        } else {
          this.titleService.setTitle(`${event.title} - DABO`);
        }
        if (this.storage.get('KFA_USER')) {
          this.UserRole = this.storage.get('KFA_USER').RoleCode;
          if (this.UserRole !== 'SU' && event.title === 'โมดูล') {
            this.router.navigate(['']);
          }
        }
      });

    this.router.events
      .pipe(
        filter((event) => event instanceof ResolveEnd),
        take(1)
      )
      .subscribe(() => (this.isLoading = false));

    this.setSignalRHubScript();
  }

  setSignalRHubScript() {
    const script = document.createElement('script');
    script.src = environment.API_HOST + 'signalr/hubs';
    document.getElementsByTagName('body')[0].appendChild(script);
  }
}
