import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { menuSidebar } from './../../../shared/models/menu-sidebar';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';
import { AppService } from 'src/app/core/services/app.service';
import { HttpService } from '../../../core/services/http.service';
import { AuthenticationService } from '../../services/authentication.service';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { Subscription } from 'rxjs';
import { CustomerSupportService } from 'src/app/services/customer-support.service';


@Component({
  selector: 'kfa-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SideBarComponent implements OnInit, OnDestroy {
  @Input() opened: boolean;

  menuSidebar = menuSidebar;
  isCollapsed: boolean;
  UserRole: string;
  modules;
  notify: any[];

  content = environment.CONTENT_HOST;
  Subscriptions: Subscription[] = [];

  constructor(
    private storage: LocalStorageService,
    private app: AppService,
    private http: HttpService,
    private auth: AuthenticationService,
    private router: Router,
    private customerSupportService: CustomerSupportService,
  ) {
    this.UserRole = this.storage.get('KFA_USER').Role;
    if (this.UserRole) {
      this.menuSidebar = this.menuSidebar.filter(menu => {
        if (menu.role) {
          if (menu.role.find(fn => fn === this.UserRole.toUpperCase())) {
            return true;
          } else {
            return false;
          }
        } else {
          return true;
        }
      });
    }

    this.app.userUpdated.subscribe(res => {
      if (this.UserRole) {
        this.menuSidebar = this.menuSidebar.filter(menu => {
          if (menu.role) {
            if (menu.role.find(fn => fn === this.UserRole.toUpperCase())) {
              return true;
            } else {
              return false;
            }
          } else {
            return true;
          }
        });
        this.getmodules();
      }
    });

    this.Subscriptions.push(
      this.customerSupportService.notiAdmin$.subscribe((noti: any) => {
        if (noti && noti.modules) { 
          this.notify = noti.modules;
          // if (this.notify[0]) {
          //   this.notify[0].amount_noti = 10; 
          // }
        }
      })
    );
  }

  ngOnInit() {
    this.getmodules();
  }

  ngOnDestroy() {
    this.Subscriptions.map((m) => m.unsubscribe()).splice(0);
  }

  getmodules() {
    this.http.get('api/bo/modules/get_by_role').subscribe((res) => {
      this.modules = res;
      let listURL = this.router.url.split('/');
      let indexURL = 0;
      if (listURL.length >= 2) {
        indexURL = res.findIndex(item => item.ModulePath === '/' + listURL[1].split('?')[0] );
      }
      if (indexURL >= 0) { 
        this.auth.setnowdata(res[indexURL].ModuleId + '');
      } else {
        this.auth.setnowdata(res[0].ModuleId + '');
      }
      this.auth.loaded.next(true);
    });
  }

  goto(menu) {
    this.auth.setnowdata(menu.ModuleId + '');
  }
}
