import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PublicComponent } from './layouts/public/public.component';
import { SecureComponent } from './layouts/secure/secure.component';
import { NoAuthGuard } from './shared/guard/no-auth.guard';
import { AuthGuard } from './shared/guard/auth.guard';
import { publicRoutes } from './core/routes/app.public.routes';
import { secureRoutes } from './core/routes/app.secure.routes';
import { AppUrls } from './shared/models/app.url';


const routes: Routes = [
  {
    path: '',
    component: PublicComponent,
    canActivate: [NoAuthGuard],
    children: publicRoutes
  },
  {
    path: '',
    component: SecureComponent,
    canActivate: [AuthGuard],
    children: secureRoutes,
  },
  {
    path: AppUrls.Help,
    component: PublicComponent,
    children: [
      { path: '', loadChildren: () => import('./modules/help/help.module').then(m => m.HelpModule)},
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
