import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { tap, mapTo, catchError, finalize } from 'rxjs/operators';
import { LocalStorageService } from './local-storage.service';
import { HttpService } from './http.service';
import { Subject, BehaviorSubject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})

export class AuthenticationService {
  readonly KFA_USER = 'KFA_USER';
  readonly KFA_TOKEN = 'KFA_TOKEN';
  readonly KFA_CURRENT = 'KFA_CURRENT';

  loaded = new BehaviorSubject(false);
  constructor(
    private http: HttpService,
    private storage: LocalStorageService,
  ) { }

  login(model: { username: string, password: string }) {
    return this.http.post('api/bo/authen/signin', model).pipe(
      tap(data => this.storeUser(data)),
      mapTo(true),
    );
  }

  logout() {
    this.removeDatas();
  }

  setnowdata(data) {
    if (!data) { return; }
    this.storage.set(this.KFA_CURRENT, data);
  }

  getnowdata() {
    return this.storage.get(this.KFA_CURRENT);
  }

  isLoggedIn() {
    return this.getToken() && this.getUser();
  }

  getUser() {
    return this.storage.get(this.KFA_USER);
  }

  getToken() {
    return this.storage.get(this.KFA_TOKEN);
  }

  private storeUser(data: any) {
    this.storage.set(this.KFA_USER, data.User);
    this.storage.set(this.KFA_TOKEN, data.Token);
    this.storage.set(this.KFA_CURRENT, '0');
  }

  private removeDatas() {
    this.storage.remove(this.KFA_USER);
    this.storage.remove(this.KFA_TOKEN);
    this.storage.remove(this.KFA_CURRENT);
  }

}
