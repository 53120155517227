<nav class="nav" [ngClass]="{'act': !opened}">
  <div class="nav-left cursor" (click)="drawerChanges()">
    <i *ngIf="!opened" class="material-icons"> arrow_forward_ios </i>
    <i *ngIf="opened" class="material-icons arrow-left"> arrow_back_ios </i>
    <div>
        <!-- <ng-container *ngFor="let menu of menuSidebar">
          <ng-container *ngIf="menu == ''">
            <i [class]="menu.icon.class">{{menu.icon.name}} </i>
            <span>{{menu.name}}</span>
          </ng-container>
        </ng-container> -->
    </div>
  </div>

  <div class="nav-right">
    <ul class="navbar-nav">
      <div class="btn-group mr-2" dropdown style="cursor: pointer">
        <a dropdownToggle class="btn-dropdown" style="align-self: center;">
          <span style="color: #007bff;">{{user.Name || user.Role}}</span>
        </a>
        <ul *dropdownMenu class="dropdown-menu dropdown-menu-right" style="position: absolute">
          <li class="menu-item">
            <a [routerLink]="['', 'edit-user']" class="dropdown-item text-secondary">
                <i class="fas fa-edit"></i> แก้ไขข้อมูล
            </a>
          </li>
        </ul>
      </div>

      <li class="nav-item">
        <a class="nav-link cursor" placement="bottom">

        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link cursor" (click)="logout()">
          <span class="mr-1">Log out</span>
          <i class="material-icons" style="color: #0588E1;"> power_settings_new </i>
        </a>
      </li>
    </ul>
  </div>
</nav>

