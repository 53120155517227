<div class="top-color"></div>

<mat-drawer-container [hasBackdrop]="false">

  <mat-drawer #drawer mode="side" [opened]="openSidebar" (openedChange)="drawerChanges($event)">
    <kfa-sidebar [opened]="openSidebar"></kfa-sidebar>
  </mat-drawer>

  <mat-drawer-content class="position-relative" style="overflow-y: auto; overflow-x: hidden;">

    <div>
      <kfa-navbar [(opened)]="openSidebar" [drawer]="drawer"></kfa-navbar>
    </div>

    <div class="content flex-grow-1">
      <router-outlet></router-outlet>
    </div>

    <div>
      <kfa-footer></kfa-footer>
    </div>

  </mat-drawer-content>

</mat-drawer-container>