<nav class="nav d-flex flex-row h-100">
  <div class="header" [ngClass]="{'act': !opened}">
    <img *ngIf="opened" src="../../../../assets/svg/Logo_DeeAction.svg" width="55px" alt="" style="margin-right: 10px;">
    <img *ngIf="!opened" src="../../../../assets/svg/logoD.svg" alt="">
    <span *ngIf="opened" style="text-transform: uppercase;"> DeeAuction </span>
  </div>
  <ul class="list-group list-group-flush" [ngClass]="{'act': !opened}">
    <li class="list-group-item" *ngFor="let menu of modules">
      <a [routerLink]="['', menu.ModulePath.split('/')[1]]" (click)="goto(menu)" routerLinkActive="act">
        <i class="material-icons" [ngClass]="{'fix-postpaid': 'postpaid' === menu.ModuleIcon }">{{menu.ModuleIcon}}</i>
        <ng-container *ngIf="notify && notify.length > 0">
          <ng-container *ngIf="menu.ModulePath === '/store' && notify[0].amount_noti > 0">
            <span class="noti-mini"></span>
          </ng-container>
          <ng-container *ngIf="menu.ModulePath === '/customer-support' && notify[1].amount_noti > 0">
            <span class="noti-mini"></span>
          </ng-container>
        </ng-container>
        <span>
          {{menu.ModuleName}}
          <ng-container *ngIf="notify && notify.length > 0">
            <ng-container *ngIf="menu.ModulePath === '/store' && notify[0].amount_noti > 0">
              <span class="alert-notify" [ngClass]="{ 'num-count': notify[0].amount_noti < 10}">
                {{ notify[0].amount_noti }}
              </span>
            </ng-container>
            <ng-container *ngIf="menu.ModulePath === '/customer-support' && notify[1].amount_noti > 0">
              <span class="alert-notify" [ngClass]="{ 'num-count': notify[0].amount_noti < 10}">
                {{ notify[1].amount_noti }}
              </span>
            </ng-container>
          </ng-container>
        </span>
      </a>
    </li>
    <!-- <li class="list-group-item" *ngFor="let menu of modules">
            <a (click)="goto(menu)">
              <i class="material-icons">{{menu.ModuleIcon}} </i>
              <span>{{menu.ModuleName}}</span>
            </a>
          </li> -->
    <li class="list-group-item footer" *ngIf="UserRole == 'Superadmin'">
      <a [routerLink]="['', 'settingmodule']" routerLinkActive="act">
        <i class="material-icons">settings</i>
        <span>โมดูล</span>
      </a>
    </li>
  </ul>

</nav>