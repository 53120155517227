import { AppUrls } from './app.url';

interface Menu {
  name: string;
  link: string;
  icon: Icon;
  role?: string[];
  subMenu?: Menu[];
}

interface Icon {
  class: string;
  name: string;
}

const Roles = {
  Operator: 'OPERATOR',
  Admin: 'ADMIN',
  Superadmin: 'SUPERADMIN'
};

export const menuSidebar: Menu[] = [
  // {
  //   name: 'Dashboard',
  //   link: AppUrls.Dashboard,
  //   icon: { class: 'material-icons', name: 'dashboard'},
  // },
  {
    name: 'สมาชิก',
    link: AppUrls.Clients,
    icon: { class: 'material-icons', name: 'account_box'},
  },
  {
    name: 'ผู้ดูแลระบบ',
    link: 'users',
    icon: { class: 'material-icons', name: 'supervisor_account'},
    role: [Roles.Admin, Roles.Superadmin]
  },
  {
    name: 'ตั้งค่า',
    link: 'setting',
    icon: { class: 'material-icons', name: 'build'},
    role: [Roles.Admin, Roles.Superadmin]
  },
  {
    name: 'ตั้งค่า module',
    link: 'settingmodule',
    icon: { class: 'material-icons', name: 'build'},
    role: [Roles.Admin, Roles.Superadmin]
  },
  // {
  //   name: 'Faq',
  //   link: AppUrls.Faq,
  //   icon: { class: 'material-icons', name: 'question_answer'},
  // },
  // {
  //   name: 'News',
  //   link: AppUrls.News,
  //   icon: { class: 'material-icons', name: 'line_style'},
  // },
  // {
  //   name: 'Help',
  //   link: AppUrls.Help,
  //   icon: { class: 'material-icons', name: 'help'},
  // },
];
